<template>
  <v-layout wrap class="ctk-footer-div">
    <v-footer class="ctk-footer" max-width="1280">
      <template>
        <!--mobile-->
        <v-layout wrap class="hidden-sm-and-down">
          <v-flex xs12 sm12 md8 lg8>
            <v-layout wrap>
              <v-flex md2>
                <div class="footer-menu" href="/research">睡眠研究</div>
                <ul class="footer-ul">
                  <li><a href="/research/1">睡眠研究资讯</a></li>
                  <li><a href="/research/2">睡眠科普知识</a></li>
                </ul>
              </v-flex>
              <v-flex md2>
                <div class="footer-menu">解决方案</div>
                <ul class="footer-ul">
                  <li><a href="/scheme">好睡眠解决方案</a></li>
                  <!-- <li><a href="/cases">睡眠环境改善案例</a></li> -->
                </ul>
              </v-flex>
              <v-flex md2>
                <a class="footer-menu" href="/about">了解好睡眠</a>
                <ul class="footer-ul">
                  <li><a href="/about#introduce">企业介绍</a></li>
                  <li><a href="/about#history">发展历程</a></li>
                  <li><a href="/about#responsibility">社会责任</a></li>
                </ul>
              </v-flex>
              <v-flex md2>
                <div class="footer-menu" href="/news">新闻资讯</div>
                <ul class="footer-ul">
                  <li><a href="/news/1">企业动态</a></li>
                  <!-- <li><a href="/news/2">媒体报道</a></li> -->
                </ul>
              </v-flex>
              <v-flex md2>
                <div class="footer-menu">
                  <a href="/member">会员中心</a>
                </div>
              </v-flex>
              <v-flex md2>
                <div class="footer-menu">
                  <a href="/contact">联系我们</a>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md4 lg4>
            <div class="footer-qr-div">
              <div class="footer-qr">
                <img :src="info.qrcode"/>
              </div>
              <div class="footer-qr-text">扫码关注好睡眠</div>
            </div>
            <div class="footer-kf-div">
              <div class="footer-kf-label">客服热线</div>
              <div class="footer-kf-tel">{{info.tel}}</div>
              <!-- <div class="footer-kf-time">周一至周日  8:30-12:00，13:00-20:00</div> -->
            </div>
          </v-flex>
          <v-flex xs12 class="text-center" style="margin-top: 92px; margin-bottom: 66px;">
            <div><img src="~@/assets/image/footer-text.png" style="height:47px;"/></div>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            上海德蛙好睡眠环境科技有限公司 Copyright © 2022 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号:
            {{ info.icp }}</a>
          </v-flex>
        </v-layout>
        <!--mobile-->
        <v-layout wrap class="hidden-md-and-up">
          <v-flex class="text-center">
              <div class="footer-qr">
                <img :src="info.qrcode"/>
              </div>
              <div class="footer-qr-text">扫码关注好睡眠</div>
              <div class="footer-kf-div">
                <span class="footer-kf-label">客服热线</span>
                <span class="footer-kf-tel">{{info.tel}}</span>
              </div>
              <div class="footer-kf-time">周一至周日  8:30-12:00，13:00-20:00</div>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            上海德蛙好睡眠环境科技有限公司 Copyright © 2022 <br/> <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号:
            {{ info.icp }}</a>
          </v-flex>
        </v-layout>
      </template>
    </v-footer>
  </v-layout>
</template>
<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      let links = []
      if (this.$store.getters.companyInfo.link) {
        links = this.$store.getters.companyInfo.link.filter(ele => ele.type === 0)
      }
      let info = this.$store.getters.companyInfo
      info.links = links
      return info
    }
  }
}
</script>
